/**
 * @generated SignedSource<<89c8167ca29d0a81049047401995fd0e>>
 * @relayHash 5485afcfc67bbd5ab415848e7222f2d1
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-admin-accounts/504.0.0-2025-04-02T17:02:50.045Z/InternalLoginFormQuery

import { ConcreteRequest } from 'relay-runtime';
export type InternalLoginFormQuery$variables = Record<PropertyKey, never>;
export type InternalLoginFormQuery$data = {
  readonly viewer: {
    readonly useOktaLogin: boolean | null;
  };
};
export type InternalLoginFormQuery = {
  response: InternalLoginFormQuery$data;
  variables: InternalLoginFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useOktaLogin",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InternalLoginFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InternalLoginFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-admin-accounts/504.0.0-2025-04-02T17:02:50.045Z/InternalLoginFormQuery",
    "metadata": {},
    "name": "InternalLoginFormQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b22d26f165d7150cd5ffd52e35f5b1b7";

export default node;
